import React, { Fragment } from 'react';
import Layout from '../components/layout';
import { string, shape, object, element, arrayOf, oneOfType } from 'prop-types';
import { graphql } from 'gatsby';

function BlogPost({
  children,
  data: {
    markdownRemark: {
      html,
      frontmatter: { title, date },
      fields: { readingTime },
    },
  },
}) {
  return (
    <Layout>
      <section>
        <h1>{title}</h1>
        <h2>{date}</h2>
        <h3>{readingTime.text}</h3>
      </section>
      <section dangerouslySetInnerHTML={{ __html: html }} />
      <Fragment>{children}</Fragment>
    </Layout>
  );
}

BlogPost.propTypes = {
  children: oneOfType([element, arrayOf(element), object, arrayOf(object)]),
  data: shape({
    markdownRemark: shape({
      html: string.isRequired,
      frontmatter: shape({
        title: string.isRequired,
        description: string,
        date: string.isRequired,
        image: object,
      }).isRequired,
      fields: shape({
        readingTime: shape({
          text: string.isRequired,
        }),
      }),
    }).isRequired,
  }).isRequired,
};

export default BlogPost;

export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "Do MMMM, YYYY")
        title
        description
        image {
          childImageSharp {
            resize(width: 1500, height: 1500) {
              src
            }
            fluid(maxWidth: 786) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        readingTime {
          text
        }
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: ASC }
      filter: {
        fields: { slug: { ne: $slug } }
        frontmatter: { draft: { ne: true }, series: { master: { ne: true } } }
      }
    ) {
      group(field: frontmatter___series___name) {
        fieldValue
        totalCount
        nodes {
          excerpt(pruneLength: 120)
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
