import React from 'react';
import BlogPost from './blog-post';
import { graphql } from 'gatsby';
import { shape, arrayOf, object, bool } from 'prop-types';
import { SeriesList } from '../components/series-list';

function MasterPost({
  data,
  data: {
    allMarkdownRemark: { nodes },
    markdownRemark: {
      frontmatter: {
        series: { master },
      },
      fields: { slug },
    },
  },
}) {
  return (
    <BlogPost data={data}>
      <SeriesList series={nodes} master={master} slug={slug} />
    </BlogPost>
  );
}

MasterPost.propTypes = {
  data: shape({
    allMarkdownRemark: shape({
      nodes: arrayOf(object),
    }),
    markdownRemark: shape({
      frontmatter: shape({ series: shape({ master: bool.isRequired }) }),
    }),
  }),
};

export default MasterPost;

export const query = graphql`
  query seriesGroup($seriesName: String, $slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "Do MMMM, YYYY")
        title
        description
        series {
          name
          master
        }
        image {
          childImageSharp {
            resize(width: 1500, height: 1500) {
              src
            }
          }
        }
      }
      fields {
        slug
        readingTime {
          text
        }
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: ASC }
      filter: {
        frontmatter: { draft: { in: [false, null] }, series: { name: { eq: $seriesName }, master: { ne: true } } }
      }
    ) {
      totalCount
      nodes {
        fields {
          slug
          readingTime {
            text
          }
        }
        excerpt(pruneLength: 200)
        frontmatter {
          date(formatString: "MMMM Do YYYY")
          title
          series {
            name
          }
        }
      }
    }
  }
`;
