import React from 'react';
import styled from 'styled-components';
import { arrayOf, object, bool, string } from 'prop-types';
import { Link } from 'gatsby';
import { get } from 'lodash';

const SeriesStyled = styled.section`
  border-top: 1px dotted rgba(242, 19, 104, 0.4);
  margin: 4rem 0 0;
  overflow: hidden;

  h3 {
    margin-top: 3rem;
  }

  ul {
    list-style-type: none;
    margin: 1rem 2rem;

    li {
      margin: 2rem 0;
    }
  }
`;

const showArticlePredicate = (post, master, mainPage, slug) => {
  if (mainPage === true) {
    return get(post, 'frontmatter.series.master') !== true;
  } else {
    return master === true || post.fields.slug !== slug;
  }
};

export function SeriesList({ series, master, slug, mainPage }) {
  const othersInSeries = series.filter(post => {
    return showArticlePredicate(post, master, mainPage, slug);
  });

  return (
    othersInSeries.length > 0 && (
      <SeriesStyled>
        {master !== true && mainPage !== true && <h3>Other blog posts in this series</h3>}
        <ul>
          {othersInSeries.map((post, index) => {
            return (
              <li key={index}>
                <div>
                  <h2>
                    <Link to={post.fields.slug} partiallyActive={true}>
                      {post.frontmatter.title}
                    </Link>
                  </h2>
                  <h3>
                    {post.frontmatter.date} - {post.fields.readingTime.text}
                  </h3>
                  <p>{post.excerpt}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </SeriesStyled>
    )
  );
}

SeriesList.propTypes = {
  series: arrayOf(object).isRequired,
  master: bool.isRequired,
  slug: string.isRequired,
};
